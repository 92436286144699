import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./pages/homeScreen";
import CaseStudies from "./pages/caseStudies";
import { Helmet } from "react-helmet";

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      console.log("registrations", registrations);
      const checkIfInstalled = () => {
        console.log(registrations[0].installing);
        if (!registrations[0].installing) {
          console.log("about to clear");
          clearInterval(refreshIntervalId);
          axios
            .get(
              `https://valid-insights-cms.betastaging.co.uk/wp-json/valid-insights/v1/pages`
            )
            .then(res => {
              setData(res.data);
              const loadScreen = document.querySelector(".loading");
              loadScreen.classList.add("loading--hide");
              loadScreen.classList.remove("loading--show");
              window.setTimeout(
                loadScreen.classList.add("loading--push-behind"),
                600
              );

              //localStorage.setItem("appState", JSON.stringify(res.data));
            })
            .catch(err => {
              console.log("error", err);
              // if (localStorage.getItem("appState")) {
              //   setData(JSON.parse(localStorage.getItem("appState")));
              // }
            });
        }
      };

      const refreshIntervalId = window.setInterval(
        () => checkIfInstalled(),
        900
      );
    });

    const loadScreen = document.querySelector(".loading");
    const home = document.querySelector(".home");
    if (home) {
      home.style.opacity = "0";
      if (home.classList) {
        home.classList.add("home--animate");
        home.style.opacity = "1";
      }
    }

    if (loadScreen && loadScreen.classList)
      loadScreen.classList.add("loading--show");
  }, []);

  return (
    <>
      <Helmet>
        <title>Valid Insight</title>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/" exact>
            <HomeScreen data={data ? data[0] : null} />
          </Route>
          <Route path="/case-studies" exact>
            <CaseStudies data={data ? data[0] : null} />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
