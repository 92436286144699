import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Resources from "../components/resources";
import CardTeaser from "../components/cardTeaser";
import maroonIcon from "../assets/images/maroonIcon.svg";
import yellowIcon from "../assets/images/yellow-icon.svg";
import turqIcon from "../assets/images/turq-icon.svg";
import blueIcon from "../assets/images/blue-icon.svg";
import greenIcon from "../assets/images/green-icon.svg";
import { Link } from "react-router-dom";
import IdleTimer from "react-idle-timer";

const CaseStudies = props => {
  const [redirect, setRedirect] = useState(null);
  if (!props.data) return null;
  let idleTimer = null;
  let caseStudies = props.data.content.case_studies;

  caseStudies = caseStudies.map((caseStudy, mainIndex) => {
    const group = caseStudy.group.map((item, index) => {
      if (mainIndex === 0 && index === 0) {
        item.icon = maroonIcon;
        item.url =
          "../iframes/early-development/iframe.html?hash=ft9fwi8jd&bgcolor=EEEEEE&download=1";
      } else if (mainIndex === 0 && index === 1) {
        item.icon = yellowIcon;
        item.url =
          "../iframes/phase-2/iframe.html?hash=fhma68lmz&bgcolor=EEEEEE&download=1";
      } else if (mainIndex === 0 && index === 2) {
        item.icon = turqIcon;
        item.url =
          "../iframes/phase-3/iframe.html?hash=fxjfwi8jd&bgcolor=EEEEEE&download=1";
      } else if (mainIndex === 1 && index === 0) {
        item.icon = blueIcon;
        item.url =
          "../iframes/filing-launch/iframe.html?hash=f198235nv&bgcolor=EEEEEE&download=1";
      } else {
        item.icon = greenIcon;
        item.url =
          "../iframes/post-launch/iframe.html?hash=fhk30n9ug&bgcolor=EEEEEE&download=1";
      }
      return item;
    });
    caseStudy.group = group;
    return caseStudy;
  });

  const onIdle = e => {
    setRedirect("/");
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        timeout={30000}
      />
      <section className="background">
        <div className="case-studies__back-btn">
          <Link to="/" className="button button--small button--arrow-left">
            Back
          </Link>
        </div>

        <div className="case-studies__container">
          {caseStudies.map(caseStudy => (
            <div className="card-teaser__container">
              <h2 className="medium uppercase text-center">
                {caseStudy.title}
              </h2>
              <div className="flex">
                {caseStudy.group.map((cardData, index) => {
                  let isLastItem = false;
                  if (index === caseStudy.group.length - 1) {
                    isLastItem = true;
                  }
                  return (
                    <CardTeaser
                      key={index}
                      data={cardData}
                      lastItem={isLastItem}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <Resources data={props.data.content.resources} />
      </section>
    </>
  );
};

export default CaseStudies;
