import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import logo from "../assets/images/logo.svg";
import secondaryLogo from "../assets/images/queens-award.svg";
import { mailChimpFormApi } from "../api/mailChimp";
import FlipBook from "./flipBook";

const Form = props => {
  const { handleSubmit, register, errors, reset } = useForm();
  const [formSent, setFormSent] = useState(false);
  const [showFlipBook, setShowFlipBook] = useState(false);

  const closeFlipBook = () => {
    setShowFlipBook(false);
  };
  const openFlipBook = () => {
    setShowFlipBook(true);
  };

  const onSubmit = (values, e) => {
    var myHeaders = new Headers();
    var formdata = new FormData();
    formdata.append("your-name", values.name);
    formdata.append("your-email", values.email);
    formdata.append("your-job", values.job);
    formdata.append("your-terms", values.termsAndConditions);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    fetch(mailChimpFormApi, requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        e.target.reset();
      })
      .catch(error => console.log("error", error));
    setFormSent(true);

    // set timer for 10 seconds
    window.setTimeout(() => setFormSent(false), 10000);
  };

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then(function(reg) {
          if ("sync" in reg) {
            // do stuff here
          }
        })
        .catch(function(err) {
          console.error(err); // the Service Worker didn't install correctly
        });
    }
  }, []);
  return (
    <>
      <div className="blur"></div>
      <div className="form-container">
        <div className="logo">
          <img src={logo} alt="VALID INSIGHT" />
        </div>
        <div className={formSent ? "fade-out" : ""}>
          <p className="medium bold">
            <strong>{props.content && props.content.heading}</strong>
          </p>
        </div>
        <div className={"form"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"form__fields" + (formSent ? " fade-out" : "")}>
              {props.content && (
                <>
                  <input
                    name="name"
                    className={errors.name ? "error" : ""}
                    placeholder={props.content.fields.name}
                    ref={register({
                      validate: value =>
                        value.length > 1 || "Please enter your name"
                    })}
                  />

                  <input
                    name="job"
                    placeholder={props.content.fields.job_title}
                    className={errors.job ? "error" : ""}
                    ref={register({
                      validate: value =>
                        value.length > 1 || "Please enter your job title"
                    })}
                  />

                  <input
                    name="email"
                    placeholder={props.content.fields.email}
                    className={errors.email ? "error" : ""}
                    ref={register({
                      required: "Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address"
                      }
                    })}
                  />

                  <input
                    type="checkbox"
                    id="termsAndConditions"
                    name="termsAndConditions"
                    ref={register({ required: true })}
                  />
                  <label
                    className={
                      "checkbox-label small semi-bold" +
                      (errors.termsAndConditions
                        ? " checkbox-label--error"
                        : "")
                    }
                    htmlFor="termsAndConditions"
                  >
                    <span>
                      {props.content.fields.privacy_checkbox}{" "}
                      <a role="button" onClick={openFlipBook}>
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </>
              )}
            </div>
            <button
              className={
                "button button--small button--fluid" +
                (formSent ? " button--transparent" : "")
              }
              type="submit"
            >
              {formSent && "Thanks!"}
              {!formSent && "Submit"}
            </button>
          </form>
        </div>
        <div className="logo--secondary">
          <img src={secondaryLogo} alt="" />
        </div>
      </div>
      <FlipBook
        data={showFlipBook}
        handleClose={closeFlipBook}
        url={
          "../iframes/privacy-policy/iframe.html?hash=fh56t240n&bgcolor=EEEEEE&download=1"
        }
      />
    </>
  );
};

export default Form;
