import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const TextBlock = props => {
  return (
    <div className="text-block">
      {props.content &&
        props.content.hasOwnProperty("content") &&
        ReactHtmlParser(props.content.content)}

      {props.content && props.content.hasOwnProperty("link") && (
        <Link
          to={"/case-studies"}
          className={`button button--large button--arrow`}
        >
          {props.content.link.name}
        </Link>
      )}
    </div>
  );
};

export default TextBlock;
