import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import FlipBook from "../components/flipBook";

const CardTeaser = props => {
  const { stage, title, colour, icon, url } = props.data;
  console.log("prop ", props);
  const [showFlipBook, setShowFlipBook] = useState(false);

  const closeFlipBook = () => {
    setShowFlipBook(false);
  };
  const openFlipBook = () => {
    setShowFlipBook(true);
  };
  let additionalClass = props.lastItem ? "card-teaser--no-border" : "";
  return (
    <>
      <div className={`card-teaser ${additionalClass}`}>
        <div className={`card-teaser__icon card-teaser__icon--${colour}`}>
          <img src={icon} alt={title} />
        </div>
        <div className="card-teaser__text-container">
          <div className="card-teaser__icon-title">
            <h3>{ReactHtmlParser(stage)}</h3>
          </div>
          <div className="card-teaser__title">
            <h3 className="medium bold text-center">
              {ReactHtmlParser(title)}
            </h3>
          </div>
          <button
            className={`button button--small button--${colour}`}
            onClick={() => openFlipBook()}
          >
            View
          </button>
        </div>
      </div>
      <FlipBook data={showFlipBook} handleClose={closeFlipBook} url={url} />
    </>
  );
};

export default CardTeaser;
