import React, { useRef, useEffect } from "react";
import crossIcon from "../assets/images/cross.svg";

const FlipBook = props => {
  //alert(props.url);
  const iframeUrl = props.url;
  const refFlipBook = useRef();

  const moveBackwards = () => {
    const { current } = refFlipBook;
    current.style.opacity = 0;
    setTimeout(() => {
      current.style.zIndex = -1;
    }, 300);
  };

  const moveForwards = () => {
    const { current } = refFlipBook;
    current.style.zIndex = 9;
    setTimeout(() => {
      current.style.opacity = 1;
    }, 300);
  };

  useEffect(() => {
    if (props.data) {
      moveForwards();
    } else {
      moveBackwards();
    }
  }, [props.data]);

  return (
    <div
      className={
        "flip-book" + (props.data ? " flip-book--show" : " flip-book--close")
      }
      ref={refFlipBook}
    >
      <div
        className="flip-book__close"
        onClick={() => {
          props.handleClose();
          moveBackwards();
        }}
      >
        <img src={crossIcon} alt="Close the pdf" />
      </div>

      <iframe
        src={iframeUrl}
        title="Flip book"
        width="100%"
        height="85%"
        seamless="seamless"
        scrolling="no"
        frameBorder="0"
        allowtransparency="true"
      ></iframe>
    </div>
  );
};

export default FlipBook;
