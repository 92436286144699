import React from "react";
import logoShape from "../assets/images/loading-logo-shape.svg";
import logoText from "../assets/images/loading-logo-text.svg";

const LoadingScreen = props => {
  return (
    <section className="loading background background--image">
      <div className="loading__progress">
        <div className="loading__logo">
          <img className="loading__logo--shape" src={logoShape} alt="" />
          <img className="loading__logo--text" src={logoText} alt="" />
        </div>
        <div className="loading__text">
          Downloading assets
          <span className="elipsis-container">
            <span className="elipsis">...</span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default LoadingScreen;
