import React, { useState } from "react";
import Swiper from "react-id-swiper";
import secondaryLogo from "../assets/images/secondary-logo.svg";
import "swiper/css/swiper.css";
import FlipBook from "./flipBook";

const Resources = props => {
  const [showFlipBook, setShowFlipBook] = useState(false);
  const [flipBookUrl, setFlipBookUrl] = useState("");
  if (!props.data) return;
  const resourceItems = props.data;

  const closeFlipBook = () => {
    setShowFlipBook(false);
  };
  const openFlipBook = () => {
    setShowFlipBook(true);
  };

  const getFlipBookUrl = async title => {
    const getUrl = async () => {
      switch (title) {
        case "Explaining the price of your product":
          setFlipBookUrl(
            "../iframes/price-of-product/iframe.html?hash=fuiqdaq8h&bgcolor=EEEEEE&download=1"
          );
          return;
        case "Response strategy & expert guidance to accelerate market access":
          setFlipBookUrl(
            "../iframes/response-strategy/iframe.html?hash=fcpsofs3x&bgcolor=EEEEEE&download=1"
          );
          return;
        case "Pricing and market access simulator":
          setFlipBookUrl(
            "../iframes/access-simulator/iframe.html?hash=fz1myjmcb&bgcolor=EEEEEE&download=1"
          );
          return;
        case "Virtual workshops to elevate value, pricing and market access strategies":
          setFlipBookUrl(
            "../iframes/travelless/iframe.html?hash=fzu5435nv&bgcolor=EEEEEE&download=1"
          );
          return;
        case "Valid Insight Brochure":
          setFlipBookUrl(
            "../iframes/brochure/iframe.html?hash=fhjarlai1&bgcolor=EEEEEE&download=1"
          );
          return;
        case "Real world evidence hurdles and the holy grail":
          setFlipBookUrl(
            "../iframes/real-evidence/iframe.html?hash=f190vs05t&bgcolor=EEEEEE&download=1"
          );
          return;
        default:
          return;
      }
    };
    await getUrl();

    return;
  };

  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  };
  return (
    <>
      <aside className="resources">
        <img
          src={secondaryLogo}
          className="resources__logo"
          alt="Valid Insight"
        />

        <h2 className="medium text-center uppercase">Resources</h2>
        <div className="resources__slider">
          <Swiper {...params}>
            {resourceItems.map(item => (
              <div>
                <div
                  className="resources__image"
                  onClick={async () => {
                    await getFlipBookUrl(item.cover_title_1);
                    openFlipBook();
                  }}
                >
                  <img
                    src={item.cover_image_1.url}
                    alt={item.cover_image_title_1}
                  />
                </div>
                <div
                  className="resources__image"
                  onClick={async () => {
                    await getFlipBookUrl(item.cover_title_2);
                    openFlipBook();
                  }}
                >
                  <img
                    src={item.cover_image_2.url}
                    alt={item.cover_image_title_2}
                  />
                </div>
              </div>
            ))}
          </Swiper>
          <p className="small text-center">Swipe left or right to view more</p>
        </div>
      </aside>
      <FlipBook
        data={showFlipBook}
        handleClose={closeFlipBook}
        url={flipBookUrl}
      />
    </>
  );
};

export default Resources;
