import React, { useEffect, useRef } from "react";
import Form from "../components/form";
import TextBlock from "../components/textBlock";
import LoadingScreen from "../components/loadingScreen";
import IdleTimer from "react-idle-timer";

const HomeScreen = props => {
  let intro = null;
  let formData = null;
  let idleTimer = null;
  const background = useRef();

  const onIdle = e => {
    document.querySelector(".form form").reset();
  };

  if (props.data) {
    intro = props.data.content.intro;
    formData = props.data.content.form;
  }

  useEffect(() => {
    setTimeout(() => {
      const isLocalhost = Boolean(
        window.location.hostname === "localhost" ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === "[::1]" ||
          // 127.0.0.0/8 are considered localhost for IPv4.
          window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
          )
      );

      if (isLocalhost) {
        const loadScreen = document.querySelector(".loading");
        if (loadScreen && loadScreen.classList) {
          loadScreen.classList.add("loading--hide");
          loadScreen.classList.remove("loading--show");
          loadScreen.classList.add("loading--push-behind");
        }
      }
    }, 600);
  }, []);

  return (
    <>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        timeout={30000}
      />
      <LoadingScreen />
      <section className="background background--image">
        <div className="home" ref={background}>
          <Form content={formData} />
          <TextBlock content={intro} />
        </div>
      </section>
    </>
  );
};

export default HomeScreen;
